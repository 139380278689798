<template>
  <b-card :title="'Edit Industry Type -'+toEditName">
    <b-row>
      <b-col cols="12">
        <b-form @submit.prevent="">
          <b-form-group label="Industry Type">
            <b-row>
              <b-col cols="12" md="8">
                <b-form-input
                  v-model="name"
                  type="text"
                  placeholder="Enter Industry Type Name (Code)"
                  required
                ></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label="Label">
            <b-row>
              <b-col cols="12" md="8">
                <b-form-input
                  v-model="label"
                  type="text"
                  placeholder="Enter Label"
                  required
                ></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label="Active">
            <b-row>
              <b-col cols="12" md="8">
                <b-form-checkbox
                id="checkbox-1"
                v-model="active"
                name="checkbox-1"
                >
                Active
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form-group>
          

          
          <b-button @click="submitIndustryType" type="submit" variant="success"
            >Save</b-button
          >
        </b-form>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { sleep }from '@/utils/utils.js'

export default {
  data() {
    return {
      name:'',
      toEditName:'',
      label:'',
      active:true,
    };
  },
  mounted() {
    this.getIndustryType();
  },
  methods: {
    ...mapActions({
      // getItems: "item/getItems",
      editIndustryType: "industryType/updateIndustryType",
      getSingleIndustryType: "industryType/getIndustryType"
    }),
    async getIndustryType(){
      var result;

      try{
        console.info(this.$route.params.id)
        result = await this.getSingleIndustryType(this.$route.params.id)
      }catch(error){
        console.error(error)
        this.$bvToast.toast(`${error.response.data.errors[0].message}`, {
          title: "Fail",
          variant: "danger",
          solid: true,
        });
        await sleep(1000)
        
        this.$router.push({ name: "industry-type" });
      }
      this.name= result.name;
      this.toEditName= result.name;
      this.label = result.label;
      this.active = result.active

    },
    submitIndustryType() {
      var data = {
        _id:this.$route.params.id,
        name:this.name,
        label:this.label,
        active:this.active
      }
      this.editIndustryType(data).then(async () => {
        this.$bvToast.toast("Successfully Edit Industry Type", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          await sleep(1000)
          this.$router.push({ name: "industry-type" });
      }).catch((error) =>{
        // console.error(error)
        this.$bvToast.toast(`${error.response.data.errors[0].message}`, {
          title: "Fail",
          variant: "danger",
          solid: true,
        });
      });
      
    },
  },
  computed: {
    // ...mapState({
    //   itemCatalogue: (state) => {
    //     return state.item.items.map((i) => {
    //       return { label: `${i.description} - ${i.itemModel} `, ...i }
    //       //return Object.assign({ value: i.itemModel, text: `${i.itemModel}` });
    //     });
    //   },
    // })
  },
};
</script>
<style scoped>
@media (max-width: 761px) {
  .hide-on-desktop { display: none !important; }
  .show-on-desktop {display: inline !important;}
}

@media (min-width: 762px) {
  .hide-on-mobile { display: none !important; }
}
</style>
